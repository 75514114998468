import type { FC, ReactNode } from 'react'

import React from 'react'
import { styled } from '@mui/material/styles'
import DocsNavbar from './navbar'
import DocsSidebar from './sidebar'

interface DocsLayoutProps {
  children?: ReactNode
}

const DocsLayoutRoot = styled('div')(({ theme }) => ({
  minHeight: 'calc(100vh - 64px)',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    marginLeft: 256
  }
}))

const DocsLayout: FC<DocsLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false)

  return (
    <DocsLayoutRoot>
      <DocsNavbar onOpenSidebar={(): void => setIsSidebarOpen(true)} />
      <DocsSidebar
        onClose={(): void => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      />
      {children}
    </DocsLayoutRoot>
  )
}

export default DocsLayout
