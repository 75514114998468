import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

type Variant = 'light' | 'primary'

interface LogoProps {
  variant?: Variant
}

const Logo = styled((props: LogoProps) => {
  const { variant, ...other } = props

  return (
    <svg
      width='46'
      height='46'
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M32.3101 15.4526L45.9077 7.76124L32.3101 0V15.4526Z'
        fill='#7CDABC'
      />
      <path
        d='M29.4461 20.1249L18.9064 14.3583L10.2914 9.63169C8.70377 8.7316 7.22017 7.65925 5.8675 6.43406C5.31568 5.85689 4.92495 5.14502 4.73436 4.36958C4.52024 3.44619 4.47553 2.4916 4.60242 1.55225C3.40246 3.03378 2.46769 4.71164 1.83942 6.51168C1.33895 8.01208 1.23731 9.61686 1.5445 11.1684C1.97169 13.2135 2.82306 15.1463 4.04361 16.8419C4.50153 17.4317 4.99048 18.0061 5.51049 18.5571C7.2069 20.2602 9.1289 21.7226 11.2228 22.9034H11.2771L19.2479 27.3351L29.4384 32.9076L35.6474 36.2915C37.2373 37.1887 38.7235 38.2585 40.079 39.4814C40.6312 40.0614 41.0218 40.7758 41.2122 41.5537C41.4272 42.4823 41.4693 43.4426 41.3363 44.3865C42.5428 42.9007 43.478 41.214 44.0994 39.4038C44.6041 37.9042 44.7058 36.2983 44.3943 34.7471C43.9697 32.7063 43.1238 30.7765 41.9107 29.0814C41.445 28.4915 40.9561 27.9249 40.4438 27.3661C38.7422 25.6689 36.8211 24.2071 34.7315 23.0198H34.6694L29.4461 20.1249Z'
        fill='#7CDABC'
      />
      <path
        d='M13.5977 30.4551L0 38.1775L13.5977 45.9387V30.4551Z'
        fill='#7CDABC'
      />
    </svg>
  )
})``

Logo.defaultProps = {
  variant: 'primary'
}

Logo.propTypes = {
  variant: PropTypes.oneOf<Variant>(['light', 'primary'])
}

export default Logo
