import type { Theme } from '@mui/material'
import type { FC, ReactNode } from 'react'

import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { Box, Drawer, useMediaQuery } from '@mui/material'

import Scrollbar from '@/components/scrollbar'
import SidebarSection from './sidebar-section'

interface SidebarProps {
  onClose?: () => void
  open?: boolean
}

interface Item {
  title: string
  children?: Item[]
  chip?: ReactNode
  icon?: ReactNode
  path?: string
}

interface Section {
  title: string
  items: Item[]
}

const sections: Section[] = [
  {
    title: 'Overview',
    items: [
      {
        title: 'Welcome',
        path: '/docs/welcome'
      },
      {
        title: 'Request Access',
        path: '/docs/request-access'
      },
      {
        title: 'Authentication',
        path: '/docs/authentication'
      },
      {
        title: 'Schema Index',
        path: '/docs/schema-index'
      },
      {
        title: 'Swagger Docs',
        path: '/docs/swagger'
      }
    ]
  },
  {
    title: 'Resources',
    items: [
      {
        title: 'Applications',
        path: '/docs/resource-applications'
      },
      {
        title: 'Users',
        path: '/docs/resource-users'
      }
    ]
  },
  {
    title: 'Support',
    items: [
      {
        title: 'Contact',
        path: '/docs/contact'
      },
      {
        title: 'Changelog',
        path: '/docs/changelog'
      }
    ]
  }
]

const Sidebar: FC<SidebarProps> = (props) => {
  const { onClose, open } = props
  const router = useRouter()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  })

  const handlePathChange = () => {
    if (!router.isReady) {
      return
    }

    if (open) {
      onClose?.()
    }
  }

  React.useEffect(handlePathChange, [router.isReady, router.asPath])

  const content = (
    <Scrollbar
      sx={{
        'height': '100%',
        '& .simplebar-content': {
          height: '100%'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          py: 2
        }}
      >
        {sections.map((section) => (
          <SidebarSection
            key={section.title}
            path={router.asPath}
            sx={{
              '& + &': {
                mt: 3
              }
            }}
            {...section}
          />
        ))}
      </Box>
    </Scrollbar>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        variant='permanent'
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !important',
            width: 256
          }
        }}
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor='right'
      onClose={onClose}
      open={open}
      variant='temporary'
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          width: 256
        }
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 100
      }}
    >
      {content}
    </Drawer>
  )
}

Sidebar.displayName = 'DocsSidebar'
Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
}

export default Sidebar
