import type { NextPageWithLayout } from '@/types/app-webui'

import React from 'react'
import Head from 'next/head'
import ErrorPage from 'next/error'
import { useRouter } from 'next/router'
import { Container } from '@mui/material'

import DocsLayout from '@/layouts/docs-layout'
import DocsContent from '@/layouts/docs-layout/content'
import { getArticleBySlug, getArticles } from '@/utils/api-docs'
// import { gtm } from '../../lib/gtm'

interface ArticlePageProps {
  article?: {
    content?: string
    slug?: string
    title?: string
  }
  children?: React.ReactNode
}

export const getStaticPaths = () => {
  const articles = getArticles(['slug'])

  return {
    paths: articles.map((article) => {
      return {
        params: {
          slug: article.slug
        }
      }
    }),
    fallback: false
  }
}

export const getStaticProps = ({ params }: { params: { slug: string } }) => {
  const article = getArticleBySlug(params.slug, ['content', 'slug', 'title'])

  return {
    props: {
      article
    }
  }
}

const ArticlePage: NextPageWithLayout = (props: ArticlePageProps) => {
  const { article } = props
  const router = useRouter()

  // React.useEffect(() => {
  //   gtm.push({ event: 'page_view' })
  // }, [])

  if (!router.isFallback && !article?.slug) {
    return <ErrorPage statusCode={404} />
  }

  return (
    <>
      <Head>
        <title>{`Docs: ${article?.title} | Solera Tech`}</title>
      </Head>
      <Container maxWidth='lg' sx={{ pb: '120px' }}>
        <DocsContent content={article?.content} />
      </Container>
    </>
  )
}

ArticlePage.getLayout = (page) => <DocsLayout>{page}</DocsLayout>

export default ArticlePage
