import type { ListItemProps } from '@mui/material'
import type { FC, ReactNode } from 'react'

import React from 'react'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import { Box, Button, Collapse, ListItem } from '@mui/material'

import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/KeyboardArrowRight'

interface SidebarItemProps extends ListItemProps {
  active?: boolean
  children?: ReactNode
  chip?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  open?: boolean
  path?: string
  title: string
}

const SidebarItem: FC<SidebarItemProps> = ({
  active,
  chip,
  children,
  depth,
  icon,
  info,
  open,
  path,
  title,
  ...other
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(!!open)

  const handleToggle = (): void => {
    setIsOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 24

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          py: 0,
          px: 2
        }}
        {...other}
      >
        <Button
          endIcon={
            !isOpen ? (
              <ChevronRightIcon fontSize='small' />
            ) : (
              <ChevronDownIcon fontSize='small' />
            )
          }
          disableRipple
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            'color': active ? 'secondary.main' : 'text.primary',
            'justifyContent': 'flex-start',
            'pl': `${paddingLeft}px`,
            'pr': 3,
            'textAlign': 'left',
            'textTransform': 'none',
            'width': '100%',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            },
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'action.active'
            },
            '& .MuiButton-endIcon': {
              color: 'action.active'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={isOpen} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </ListItem>
    )
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
    >
      <NextLink href={path as string} passHref>
        <Button
          component='a'
          startIcon={icon}
          endIcon={chip}
          disableRipple
          sx={{
            'borderRadius': 1,
            'color': 'text.primary',
            'justifyContent': 'flex-start',
            'pl': `${paddingLeft}px`,
            'pr': 3,
            'textAlign': 'left',
            'textTransform': 'none',
            'width': '100%',
            ...(active && {
              backgroundColor: 'rgba(255,255,255, 0.08)',
              color: 'secondary.main',
              fontWeight: 'fontWeightBold'
            }),
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'action.active'
            },
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
      </NextLink>
    </ListItem>
  )
}

SidebarItem.displayName = 'DocsSidebarItem'
SidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
}

SidebarItem.defaultProps = {
  active: false,
  open: false
}

export default SidebarItem
