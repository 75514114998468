import type { MutableRefObject } from 'react'
import type { SxProps } from '@mui/system'
import type { Theme } from '@mui/material'

import 'simplebar/dist/simplebar.min.css'

import React from 'react'
import SimpleBar from 'simplebar-react'
import { styled } from '@mui/material/styles'

interface ScrollbarProps extends SimpleBar.Props {
  ref: MutableRefObject<SimpleBar>
  sx?: SxProps<Theme>
}

const ScrollbarRoot = styled(SimpleBar)``

const Scrollbar = React.forwardRef<MutableRefObject<SimpleBar>, ScrollbarProps>(
  // @ts-ignore
  (props, ref) => <ScrollbarRoot ref={ref} {...props} />
)

Scrollbar.displayName = 'Scrollbar'

export default Scrollbar
